export enum CompanyKeys {
  BYDESIGN_HOMES = 'bydesignhomes',
  BROWNS_ESTATE_AGENTS = 'brownsestateagents',
  NEIL_KING = 'neilking',
  ALLEN_RESIDENTAL = 'allenresidential',
  ICEBERG_DIGITAL_ZENDEKS = 'icebergdigitalzendesk',
  GREY_AND_CO = 'greyandco',
  PRICE_ESTATE_AGENTS = 'priceestateagents',
  ICEBERG_DIGITAL = 'icebergdigital',
  KEYS_AND_LEE = 'keysandlee',
  BEERCOCKS = 'beercocks',
  NOEIGHTYSIXESTATEAGENCY = 'noeightysixestateagency',
  NICHOLSONSESTATEAGENTS = 'nicholsonsestateagents',
  ESTATE_AGENCY_X = 'estateagencyx',
}

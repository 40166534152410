import { defineAsyncComponent } from 'vue'
export default {
  default: defineAsyncComponent(() => import("/vercel/path0/layouts/default.vue").then(m => m.default || m)),
  theme0: defineAsyncComponent(() => import("/vercel/path0/layouts/theme0.vue").then(m => m.default || m)),
  theme2: defineAsyncComponent(() => import("/vercel/path0/layouts/theme2.vue").then(m => m.default || m)),
  theme3: defineAsyncComponent(() => import("/vercel/path0/layouts/theme3.vue").then(m => m.default || m)),
  theme4: defineAsyncComponent(() => import("/vercel/path0/layouts/theme4.vue").then(m => m.default || m)),
  theme5: defineAsyncComponent(() => import("/vercel/path0/layouts/theme5.vue").then(m => m.default || m)),
  theme6: defineAsyncComponent(() => import("/vercel/path0/layouts/theme6.vue").then(m => m.default || m)),
  theme7: defineAsyncComponent(() => import("/vercel/path0/layouts/theme7.vue").then(m => m.default || m))
}